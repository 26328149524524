import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"

const ComponentName = ({ data }) => {
  const { Info, Title, Description } = data.box

  return (
    <Layout>
      <SEO Title={Title} Description={Description} />
      <section className="box-template">
        <div className="section-center">
          <article className="box-Info">
            <ReactMarkdown source={Info} />
          </article>
          <Link to="/boxes" className="btn center-btn">
            boxes
          </Link>
          <br />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBox($Slug: String) {
    box: strapiBoxes(Slug: { eq: $Slug }) {
      Info
      Title
      Description
    }
  }
`

export default ComponentName
